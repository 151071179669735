#root,body,html{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}
.ant-layout{
    display: flex;
    width: 100%;
    min-height: 100%;
    position: relative;
}
.it-theme-header{
    background: #fff;
    padding-right: 0px;
    box-shadow: 0 2px 8px #f0f1f2;
    z-index: 10;
    max-width: 100%;
    padding-left: 16px;
    height: 56px;
}
.it-theme-nav{
    border-bottom: 0px;
    .ant-menu-title-content{
        & > A{
            font-size: 16px;
        }
    }
}
ul.it-theme-nav > li.ant-menu-item::after{
    left: 10px!important;
    right: 10px!important;
}
.it-theme-content{
    // background-color: #fff;
    display: flex;
    // display: block;
    flex: 1;
    position:absolute;
    top: 0px;
    bottom: 30px;
    width: 100%;
}
.ant-layout-footer{
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    display: block;
    position:absolute;
    width: 100%;
    bottom: 0px;
    height: 30px;
    padding: 5px 0px;
    .banner{
        color: #897e7e;
        font-size: 12px;
        line-height: 40px;
        text-align: center;
        a{
            color: #897e7e;
            margin-right: 30px;
        }
    }
}
.site-layout-background {
    background: #fff;
}
.main-wrapper {
    position: relative;
    padding: 0px;
    overflow: auto;
    // width: 100%;
    left: 0px;
    right: 0px;
    height: 100%;
    margin: 0;
    border-radius: 0;
    // background: #fff;
    padding-bottom: 50px;
    overflow-x: hidden;
}

.it-user-menus{
    width: 130px;
    & > .ant-dropdown-menu{
        margin-top: 20px;
    }
}